import {graphql} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import React from "react";

import Layout from "../../../components/Layout";
import BlogGallery from "../../../createdpages/blog/BlogGallery/BlogGallery";

const Awardstag = ({data}) => {
  const tagPosts = data.sanityPresspage.posts.filter((post) => {
    if (post.post_tag.indexOf("🏅 Awards") >= 0) {
      return true;
    }
    return false;
  });
  return (
    <Layout seoData={{title: "Mason | Awards"}}>
      <div className='full-bleed layouted bg-gray-200'>
        <div className='tag-header flex flex-col md:flex-row items-center mt-36 mb-12'>
          <div className='tag-icon mr-4 p-4 shadow-md'>
            <StaticImage
              src='https://media.kubric.io/api/assetlib/65d408cc-0def-4f13-ac6d-689674464ca4.png'
              alt='img'
            />
          </div>
          <div className='tag-content w-full md:w-2/4 p-4 md:px-0'>
            <h2 className='md:text-left'>🏅 Awards</h2>
            <span>Our awards</span>
          </div>
        </div>
        <BlogGallery data={tagPosts} post_type='link' />
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    sanityPresspage {
      posts {
        author_dp {
          asset {
            gatsbyImageData
          }
        }
        authorAlt
        author
        post_tag
        title
        url
        img {
          asset {
            gatsbyImageData
          }
        }
        alt
      }
    }
  }
`;

export default Awardstag;
